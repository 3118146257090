import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { appSettings } from '../helpers/settings';
import { ToastContainer, Flip } from 'react-toastify';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig, useNetwork } from 'wagmi';
import { getAccount, watchAccount, getContract } from '@wagmi/core';
import AOS from 'aos';

// 3RD-PARTY STYLES
import 'react-toastify/dist/ReactToastify.css';
import 'react-h5-audio-player/lib/styles.css';
import 'react-tippy/dist/tippy.css';
import 'react-range-slider-input/dist/style.css';

// HOOKS
import useWeb3 from '../hooks/useWeb3';
import useApp from '../hooks/useApp';
import useUser from '../hooks/useUser';
import useCampaign from '../hooks/useCampaign';

// CONTRACT ABIs
import UserContractAbi from '../contracts/UserContract.json';
import CrowdFundAbi from '../contracts/Crowdfund.json';
import CampaignAbi from '../contracts/CampaignContract.json';

// COMPONENTS
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';
import ScrollToTop from '../components/general/ScrollToTop';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import DonatePopup from '../components/general/DonatePopup';
import ViewOnlyAlert from '../components/general/ViewOnlyAlert';

// CHANGE [bscTestnet] with the chain name you'll deploy on, visit https://wagmi.sh/core/chains#supported-chains to select it from the list
import { bsc } from 'wagmi/chains';
const chains = [bsc];
const projectId = appSettings.wcProjectId;

// CONFIGURE WAGMI CLIENT
const { provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 2, chains }),
    provider,
    webSocketProvider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function Layout() {
    const { account, loadAccount } = useWeb3();
    const { campaignContract, campaignContractAbi, loadAllCampaigns, loadCampaignContract, getCampaignContractAbi } =
        useCampaign();
    const {
        transactionLoading,
        donateModalStatus,
        loadAppOwner,
        getContractAbi,
        loadContract,
        loadPaymentTokens,
        abi,
        contract,
    } = useApp();
    const {
        userContract,
        userContractAbi,
        loadUsersList,
        loadUserContract,
        getUserContractAbi,
        loadUserInfo,
        loadActivities,
    } = useUser();
    const { chain } = useNetwork();

    /* --------------------------------------------- 
          LOAD APP OWNER & PAYMENT TOKENS
    --------------------------------------------- */
    useEffect(() => {
        if (contract && account) {
            loadAppOwner(contract, abi);
            loadPaymentTokens(contract, abi, account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract, account]);

    /* ---------------------------------------------------- 
          LOAD USER INFORMATION & USERS LIST & ACTIVITIES
    ---------------------------------------------------- */
    useEffect(() => {
        if (userContract && account) {
            loadUserInfo(userContract, account);
        }

        if (userContract) {
            loadUsersList(userContract, userContractAbi);
            loadActivities(userContract, userContractAbi);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContract, account]);

    /* --------------------------------------------- 
          LOAD ALL CAMPAIGNS
    --------------------------------------------- */
    useEffect(() => {
        if (campaignContract) {
            loadAllCampaigns(campaignContract, campaignContractAbi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignContract]);

    /* --------------------------------------------- 
          AOS ANIMATION
    --------------------------------------------- */
    useEffect(() => {
        AOS.init({
            duration: 700,
            once: true,
        });
    }, []);

    /* --------------------------------------------- 
          GET INITIAL BLOCKCHAIN DATA
    --------------------------------------------- */
    useEffect(() => {
        const calclateInitialSettings = async () => {
            const acc = getAccount();
            loadAccount(acc?.address);
            getUserContractAbi(UserContractAbi.abi);
            getContractAbi(CrowdFundAbi.abi);
            getCampaignContractAbi(CampaignAbi.abi);

            const userDeployedNetwork = UserContractAbi.networks[appSettings.networkId];
            const cfDeployedNetwork = CrowdFundAbi.networks[appSettings.networkId];
            const cmpDeployedNetwork = CampaignAbi.networks[appSettings.networkId];

            const userContract =
                userDeployedNetwork &&
                getContract({
                    address: userDeployedNetwork.address,
                    abi: UserContractAbi.abi,
                });

            const cfContract =
                cfDeployedNetwork &&
                getContract({
                    address: cfDeployedNetwork.address,
                    abi: CrowdFundAbi.abi,
                });

            const camapignCtr =
                cmpDeployedNetwork &&
                getContract({
                    address: cmpDeployedNetwork.address,
                    abi: CampaignAbi.abi,
                });

            loadUserContract(userContract);
            loadContract(cfContract);
            loadCampaignContract(camapignCtr);

            // eslint-disable-next-line no-unused-vars
            const unwatch = watchAccount((account) => {
                loadAccount(account?.address);
            });
        };
        calclateInitialSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <WagmiConfig client={wagmiClient}>
                <div className='app pb-0'>
                    {chain?.id !== appSettings.networkId && <ViewOnlyAlert />}
                    <Header />
                    <ScrollToTop />
                    <Outlet />
                    <Footer />
                </div>
                {donateModalStatus && <DonatePopup />}
                <ToastContainer position='top-center' autoClose={1500} transition={Flip} />
                {transactionLoading && <MetaMaskLoader />}
            </WagmiConfig>
            <Web3Modal
                projectId={projectId}
                ethereumClient={ethereumClient}
                themeVariables={{
                    '--w3m-z-index': '9999',
                    '--w3m-container-background-color': 'rgba(0,0,0,.7)',
                }}
            />
        </>
    );
}

export default Layout;
