import React from 'react';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';

function AboutUsPage() {
    return (
        <>
            <PageBanner
                heading='About Us'
                text='Send money internationally, check exchange rates, and use free currency tools.'
            ></PageBanner>

            <section className='pb-5'>
                <div className='container pb-5'>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row gy-4 gx-5 justify-content-between'>
                                <div className='col-lg-4'>
                                    <h2>Some words about</h2>
                                    <p className='text-muted'>Bublik Gift launched 5 April 2024</p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    The first decentralized web3 crowdfunding platform created by the 
                                    founders of the Bublik meme coin. The Bublik Gift will enter the
                                    ecosystem of the fireplace meme, which will give it international
                                    greatness.
                                    </p>
                                </div>
                                <div className='col-lg-4'>
                                    <p>
                                    We are proud of the bagel and glad that you took our project
                                    seriously. We will dedicate our lives to our bagel and your interest in it
                                    gives us the opportunity to create the best WEB 3 solutions.
                                    </p>
                                    <h5 className='text-primary mb-0'>Bublik Coin</h5>
                                    <p className='text-muted'>Dmitry A. CEO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 bg-darker'>
                <div className='container py-5'>
                    <header className='mb-5 text-center'>
                        <h2 className='h1'>Top Features</h2>
                        <p className='text-muted'>Top 3 Features in our app that are unrivaled!</p>
                    </header>
                    <div className='row'>
                        <div className='col-lg-10 mx-auto'>
                            <div className='row g-4 text-center'>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h6 style={{margin:0}}>Decentralized</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h6 style={{margin:0}}>Easy Money Transfer</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='card mb-0 p-lg-4 shadow-0'>
                                        <div className='card-body'>
                                            <h6 style={{margin:0}}>Freindly User Interface</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container text-center py-5'>
                    <div className='row'>
                        <div className='col-lg-6 mx-auto'>
                            <p className='h3 fw-light'>
                                "With Bublik we are already in the future, first of all."
                            </p>
                            <h5 className='text-primary'>Dmitry</h5>
                            <p className='text-muted'>Bublik Coin, CEO</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsPage;
