import React from 'react';
import ReactDOM from 'react-dom';
import './scss/app.scss';
import App from './App';
import Web3Provider from './providers/Web3Provider';
import AppProvider from './providers/AppProvider';
import UserProvider from './providers/UserProvider';
import CampaignProvider from './providers/CampaignProvider';

ReactDOM.render(
    <React.StrictMode>
        <Web3Provider>
            <UserProvider>
                <CampaignProvider>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </CampaignProvider>
            </UserProvider>
        </Web3Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
