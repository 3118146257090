import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'aos/dist/aos.css';
import * as bootstrap from 'bootstrap';

// PAGES
import HomePage from './pages/home';
import Layout from './pages/Layout';
import NotFound from './components/NotFound';
import RegisterPage from './pages/register';
import CreateCampaignPage from './pages/campaign-create';
import CampaingSinglePage from './pages/campaign-single';
import CampaignsPage from './pages/campaigns';
import CategoryPage from './pages/category';
import AccountPage from './pages/account';
import AboutUsPage from './pages/about';
import useApp from './hooks/useApp';
import useWeb3 from './hooks/useWeb3';
import AdminPanel from './pages/admin';
import ActivityPage from './pages/activity';
import FAQsPage from './pages/faqs';

// HOOKS
import useUser from './hooks/useUser';

window.bootstrap = bootstrap;

function App() {
    const { isRegistered } = useUser();
    const { owner } = useApp();
    const { account } = useWeb3();

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route exact path='/' element={<HomePage />} />
                    {!isRegistered && <Route path='/signup' element={<RegisterPage />} />}
                    <Route path='/create-campaign' element={<CreateCampaignPage />} />
                    <Route path='/campaigns/:title' element={<CampaingSinglePage />} />
                    <Route path='/campaigns' element={<CampaignsPage />} />
                    <Route path='/campaigns/category/:category' element={<CategoryPage />} />
                    <Route path='/about-us' element={<AboutUsPage />} />
                    <Route path='/activities' element={<ActivityPage />} />
                    <Route path='/faqs' element={<FAQsPage />} />
                    {isRegistered && <Route path='/account' element={<AccountPage />} />}
                    {account === owner && <Route path='/admin' element={<AdminPanel />} />}

                    {/* 404 */}
                    <Route path='/*' element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
