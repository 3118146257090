import React, { useEffect } from 'react';
import { appSettings } from '../../helpers/settings';

// COMPONENTS
import HeroBanner from './HeroBanner';
import IntroBanner from './IntroBanner';
import RecentFundraises from './RecentFundraises';
import AboutSection from './AboutSection';
import HomeCategories from './HomeCategories';
import PendingCampaigns from './PendingCampaigns';
import FundedCampaigns from './FundedCampaigns';

function HomePage() {
    useEffect(() => {
        document.title = `${appSettings.brandName} | ${appSettings.brandDescription}`;
    }, []);

    return (
        <>
            <HeroBanner />
            <IntroBanner />
            <RecentFundraises />
            <AboutSection />
            <FundedCampaigns />
            <HomeCategories />
            <PendingCampaigns />
        </>
    );
}

export default HomePage;
