import React, { useEffect, useMemo } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useContractWrite } from 'wagmi';
import { toBlockExplorer } from '../../helpers/utils';

// HOOKS
import useCampaign from '../../hooks/useCampaign';
import useWeb3 from '../../hooks/useWeb3';
import useUser from '../../hooks/useUser';
import useApp from '../../hooks/useApp';

// COMPONENTS
import CampaignContributors from './AllContributors';
import NotFound from '../../components/NotFound';
import ClaimFundsHandler from '../../components/general/ClaimFunds';
import UserCampaignContributions from './UserContribution';

function CampaingSinglePage() {
    const { account } = useWeb3();
    const { allCampaigns, campaignContract, campaignContractAbi, loadAllCampaigns } = useCampaign();
    const { paymentTokens, setTransactionLoading } = useApp();
    const { title } = useParams();
    const { usersList } = useUser();
    const today = new Date().getTime();
    const navigate = useNavigate();

    // TARGET CAMPAIGN
    const currentCampaign = useMemo(() => {
        return allCampaigns?.filter((camp) => camp?.title === title)[0];
    }, [title, allCampaigns]);

    // GET DONATION PERCENTAGE
    const raisePercentage = useMemo(() => {
        return (currentCampaign?.pledged / currentCampaign?.goal) * 100;
    }, [currentCampaign]);

    // CHECK IF THIS CAMPAIGN HAS NOT LAUNCHED YET
    const isPending = useMemo(() => {
        return currentCampaign?.startAt > today;
    }, [currentCampaign, today]);

    // GET THE CAMPAIGN OWNER
    const campaignOwner = useMemo(() => {
        return usersList?.filter((user) => user?.address === currentCampaign?.creator)[0];
    }, [usersList, currentCampaign]);

    // CHANGE THE PAGE NAME TO THE CAMPAIGN TITLE
    useEffect(() => {
        document.title = `${appSettings.brandName} | ${currentCampaign?.title || 'Not Found'}`;
    }, [currentCampaign]);

    /* --------------------------------------------- 
              CANCEL CAMPAIGN HANDLER
     --------------------------------------------- */
    const { write: web3CancelCampaign } = useContractWrite({
        address: campaignContract?.address,
        abi: campaignContractAbi,
        functionName: 'cancel',
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadAllCampaigns(campaignContract, campaignContractAbi);
                navigate('/');
                toast.success('Campaign has been canceled');
            }, 5000);
        },
        onMutate() {
            setTransactionLoading(true);
        },
        onError(error) {
            setTransactionLoading(false);
            toast.error('Oops! Something went error');
        },
    });

    /* --------------------------------------------- 
              TRIGGER CANCELATION
        --------------------------------------------- */
    function cancelCampaignHandler() {
        web3CancelCampaign({
            recklesslySetUnpreparedArgs: [Number(currentCampaign?.id)],
        });
    }

    const renderer = ({ days, hours, minutes, seconds }) => (
        <div className='countdown lead'>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(days)}</strong>
                <p className='mb-0 text-sm text-muted'>Days</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(hours)}</strong>
                <p className='mb-0 text-sm text-muted'>Hours</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(minutes)}</strong>
                <p className='mb-0 text-sm text-muted'>Minutes</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(seconds)}</strong>
                <p className='mb-0 text-sm text-muted'>Seconds</p>
            </div>
        </div>
    );

    return (
        <>
            {currentCampaign ? (
                <section className='hero-banner'>
                    <div className='hero-banner-bg'></div>
                    <div className='container z-index-20 hero-banner-container'>
                        <div className='row justify-content-center g-5'>
                            <div className='col-lg-5 text-center'>
                                <img
                                    src={currentCampaign?.cover}
                                    alt={`${currentCampaign?.title}`}
                                    className='img-fluid campaign-single-img w-100'
                                />
                                <UserCampaignContributions
                                    id={currentCampaign?.id}
                                    endAt={currentCampaign?.endAt}
                                    goal={currentCampaign?.goal}
                                    pledged={currentCampaign?.pledged}
                                    ended={currentCampaign?.ended}
                                />
                            </div>
                            <div className='col-lg-5'>
                                <p>
                                    <Link
                                        to={`/campaigns/category/${currentCampaign?.category}`}
                                        className='text-reset'
                                    >
                                        <span className='badge bg-primary fw-normal lead'>
                                            {currentCampaign?.category}
                                        </span>
                                    </Link>
                                </p>
                                <h1 className='text-xl mb-3'>{currentCampaign?.title}</h1>
                                {campaignOwner && (
                                    <a
                                        className='d-inline-block text-reset'
                                        href={`${toBlockExplorer('address', campaignOwner?.address)}`}
                                        rel='noopener noreferrer'
                                        target='_blank'
                                    >
                                        <div className='glass-bg p-3 mb-3'>
                                            <p className='text-xs'>Created By</p>
                                            <div className='d-flex align-items-center'>
                                                <div
                                                    className='flex-shrink-0 bg-cover bg-center'
                                                    style={{
                                                        width: '40px',
                                                        height: '40px',
                                                        backgroundImage: `url(${campaignOwner?.profile})`,
                                                        borderRadius: '0.5rem',
                                                    }}
                                                ></div>
                                                <div className='ms-3'>
                                                    <h6 className='mb-0' style={{ fontSize: '0.9rem' }}>
                                                        {campaignOwner?.name}
                                                    </h6>
                                                    <p className='text-muted small mb-0'>{campaignOwner?.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                )}
                                <p className='text-muted'>{currentCampaign?.description}</p>

                                {/* PROGRESS */}
                                <div className='goal my-4'>
                                    <div className='d-flex align-items-center justify-content-between text-sm'>
                                        <span className='text-muted'>Raised: {currentCampaign?.pledged}</span>
                                        <span className='text-muted'>{raisePercentage?.toFixed(2)}%</span>
                                    </div>

                                    <div className='progress my-2' style={{ height: '4px' }}>
                                        <div
                                            className='progress-bar'
                                            role='progressbar'
                                            style={{ width: `${raisePercentage}%` }}
                                            aria-valuenow={raisePercentage}
                                            aria-valuemin='0'
                                            aria-valuemax='100'
                                        ></div>
                                    </div>

                                    <p className='h6 mb-3'>
                                        Goal <span className='text-primary'>{currentCampaign?.goal}</span>{' '}
                                        <span className='text-xs'>USD</span>
                                    </p>

                                    {paymentTokens?.length > 0 && (
                                        <p className='small'>
                                            Accepts{' '}
                                            {paymentTokens?.map((token, index) => {
                                                return (
                                                    <a
                                                        href={`${toBlockExplorer('token', token?.address)}`}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        className='text-reset d-inline-block'
                                                        key={index}
                                                    >
                                                        <span className='badge me-1 mb-1 bg-secondary' key={index}>
                                                            {token?.symbol}
                                                        </span>
                                                    </a>
                                                );
                                            })}
                                        </p>
                                    )}

                                    {!isPending ? (
                                        <div className='mt-3'>
                                            <ClaimFundsHandler
                                                customClass='btn btn-primary w-100'
                                                endAt={currentCampaign?.endAt}
                                                goal={currentCampaign?.goal}
                                                pledged={currentCampaign?.pledged}
                                                id={currentCampaign?.id}
                                                creator={currentCampaign?.creator}
                                                claimed={currentCampaign?.claimed}
                                            />
                                        </div>
                                    ) : (
                                        new Date().getTime() < currentCampaign?.startAt && (
                                            <div className='glass-bg p-4'>
                                                <h6 className='text-center mb-3'>This Campaign will start at</h6>
                                                <Countdown
                                                    date={currentCampaign?.startAt}
                                                    renderer={renderer}
                                                    onComplete={() => {
                                                        loadAllCampaigns(campaignContract, campaignContractAbi);
                                                    }}
                                                />
                                                {account === currentCampaign?.creator && (
                                                    <button
                                                        className='glassy-btn w-100 mt-3'
                                                        type='button'
                                                        onClick={cancelCampaignHandler}
                                                    >
                                                        Cancel this campaign
                                                    </button>
                                                )}
                                            </div>
                                        )
                                    )}

                                    <div className='my-4'>
                                        <CampaignContributors id={currentCampaign?.id} />
                                    </div>

                                    {new Date().getTime() > currentCampaign?.startAt && (
                                        <div className='glass-bg p-4'>
                                            {new Date().getTime() < currentCampaign?.endAt ? (
                                                <>
                                                    <h6 className='text-center mb-3'>This Campaign is valid until</h6>
                                                    <Countdown
                                                        date={currentCampaign?.endAt}
                                                        renderer={renderer}
                                                        onComplete={() => {
                                                            loadAllCampaigns(campaignContract, campaignContractAbi);
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <p className='mb-0'>This campaign has been ended</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default CampaingSinglePage;
