import React, { useMemo } from 'react';
import { RiTimerFlashLine } from 'react-icons/ri';
import { formatSimpleDate, toBlockExplorer } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';

// HOOKS
import useCampaign from '../../hooks/useCampaign';
import useApp from '../../hooks/useApp';
import UserDonations from './UserDonations';

// COMPONENTS
import ClaimFundsHandler from './ClaimFunds';

function FundraiseCard({ id, cover, title, category, startAt, endAt, creator, goal, pledged, pending, claimed }) {
    const { campaignContract, campaignContractAbi, loadAllCampaigns } = useCampaign();
    const raisePercentage = useMemo(() => {
        return (pledged / goal) * 100;
    }, [goal, pledged]);

    const { paymentTokens } = useApp();

    const renderer = ({ days, hours, minutes, seconds }) => (
        <div className='countdown'>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(days)}</strong>
                <p className='mb-0 text-sm text-muted'>Days</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(hours)}</strong>
                <p className='mb-0 text-sm text-muted'>Hours</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(minutes)}</strong>
                <p className='mb-0 text-sm text-muted'>Minutes</p>
            </div>
            <div className='countdown-item my-0'>
                <strong className='fw-bold'>{zeroPad(seconds)}</strong>
                <p className='mb-0 text-sm text-muted'>Seconds</p>
            </div>
        </div>
    );

    return (
        <>
            <div className='card fund-card overflow-hidden mb-0'>
                <div className='card-body p-0'>
                    {/* IMAGE */}
                    <div className='fund-card-img'>
                        <img src={cover} alt={title} className='img-fluid mb-3' />
                        <UserDonations id={id} />
                    </div>

                    <div className='px-4 pt-4'>
                        {/* CATEGORY & TIMER */}
                        <ul className='list-inline text-sm text-muted'>
                            <li className='list-inline-item'>
                                <Link to={`/campaigns/category/${category}`} className='text-reset'>
                                    <span className='badge bg-primary'>{category}</span>
                                </Link>
                            </li>
                            <li className='list-inline-item'>
                                <RiTimerFlashLine className='text-primary' /> Ends at{' '}
                                <span className='text-primary'>{formatSimpleDate(endAt)}</span>
                            </li>
                        </ul>

                        {/* TITLE */}
                        <h2 className='h5 mb-0'>
                            <Link to={`/campaigns/${title}`} className='text-reset'>
                                {title}
                            </Link>
                        </h2>

                        {/* PROGRESS */}
                        <div className='goal my-4'>
                            <div className='d-flex align-items-center justify-content-between text-sm'>
                                <span className='text-muted'>Raised: {pledged}</span>
                                <span className='text-muted'>{raisePercentage?.toFixed(2)}%</span>
                            </div>

                            <div className='progress my-2' style={{ height: '4px' }}>
                                <div
                                    className='progress-bar'
                                    role='progressbar'
                                    style={{ width: `${raisePercentage}%` }}
                                    aria-valuenow={raisePercentage}
                                    aria-valuemin='0'
                                    aria-valuemax='100'
                                ></div>
                            </div>

                            <p className='h6 mb-3'>
                                Goal <span className='text-primary'>{goal}</span> <span className='text-xs'>USD</span>
                            </p>

                            {paymentTokens?.length > 0 && (
                                <p className='small'>
                                    Accepts{' '}
                                    {paymentTokens?.map((token, index) => {
                                        return (
                                            <a
                                                href={`${toBlockExplorer('token', token?.address)}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className='text-reset d-inline-block'
                                                key={index}
                                            >
                                                <span className='badge me-1 mb-1 bg-secondary'>{token?.symbol}</span>
                                            </a>
                                        );
                                    })}
                                </p>
                            )}

                            {!pending ? (
                                <div className='mt-3'>
                                    <ClaimFundsHandler
                                        customClass='btn btn-primary w-100'
                                        endAt={endAt}
                                        goal={goal}
                                        pledged={pledged}
                                        id={id}
                                        creator={creator}
                                        claimed={claimed}
                                    />
                                </div>
                            ) : (
                                <Countdown
                                    date={startAt}
                                    renderer={renderer}
                                    onComplete={() => {
                                        loadAllCampaigns(campaignContract, campaignContractAbi);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FundraiseCard;
