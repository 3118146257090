import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { fixNavbarToTop, truncate } from '../../helpers/utils';
import { Web3Button } from '@web3modal/react';
import { useBalance } from 'wagmi';
import { appSettings } from '../../helpers/settings';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';

function Navbar() {
    const { account } = useWeb3();
    const { userInfo } = useUser();
    const { owner } = useApp();

    const { data } = useBalance({
        address: account,
    });

    /*** -------------------------------------------- */
    //      FIXING NAVBAR TO TOP
    /*** -------------------------------------------- */
    useEffect(() => {
        fixNavbarToTop();
    }, []);

    return (
        <header className='main-header fixed-top'>
            <div className='container'>
                <nav className='navbar w-100 navbar-expand-lg px-0 justify-content-between rounded-0 shadow-0'>
                    <Link className='navbar-brand' to='/'>
                        <img
                            src={`${appSettings?.logo}`}
                            alt={`${appSettings?.brandName}`}
                            width={appSettings.logoWidth}
                            className='img-fluid'
                        />
                    </Link>

                    <button
                        className='navbar-toggler shadow-0 p-0 border-0'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#navbarSupportedContent'
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                    >
                        <span className='navbar-toggler-icon-el'>
                            <span className='btn-mobile--menu-icon'></span>
                        </span>
                    </button>

                    <div className='collapse navbar-collapse justify-content-lg-between' id='navbarSupportedContent'>
                        <ul className='navbar-nav mx-auto navbar-nav-custom'>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/' end>
                                    Home
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/campaigns'>
                                    Campaigns
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/activities'>
                                    Activity
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/about-us'>
                                    About Us
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/faqs'>
                                    FAQs
                                </NavLink>
                            </li>
                        </ul>

                        <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
                            <div className='me-3 flex-shrink-0'>
                                <Web3Button />
                            </div>
                            {account && !userInfo?.address ? (
                                <Link to='/signup' className='btn btn-primary py-2'>
                                    <span className='text-sm'>Get Started</span>
                                </Link>
                            ) : (
                                userInfo?.address && (
                                    <div className='dropdown'>
                                        <Link
                                            className='px-0 dropdown-toggle no-caret text-reset'
                                            id='accountDropdown'
                                            to='/'
                                            role='button'
                                            data-bs-toggle='dropdown'
                                            data-bs-target='#userDropdown'
                                            aria-expanded='false'
                                        >
                                            <div>
                                                <h6 className='mb-0'>{userInfo?.name}</h6>
                                                <p className='text-sm text-muted mb-0'>
                                                    {truncate(userInfo?.email, 20, '...')}
                                                </p>
                                                <p className='h6 mb-0 text-primary'>
                                                    {Number(data?.formatted)?.toFixed(3)} {appSettings?.nativeCurrency}
                                                </p>
                                            </div>
                                        </Link>

                                        <ul className='dropdown-menu dropdown-menu-lg-end' id='userDropdown'>
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/account'
                                                    className='dropdown-item rounded'
                                                >
                                                    My Account
                                                </NavLink>
                                            </li>
                                            {owner === account && (
                                                <li>
                                                    <NavLink
                                                        rel='noopener noreferrer'
                                                        to='/admin'
                                                        className='dropdown-item rounded'
                                                    >
                                                        Admin Panel
                                                    </NavLink>
                                                </li>
                                            )}
                                            <li>
                                                <NavLink
                                                    rel='noopener noreferrer'
                                                    to='/create-campaign'
                                                    className='btn btn-primary w-100 btn-sm text-white bg-primary border-primary mt-2 py-2'
                                                >
                                                    Create Campaign
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;
