import React, { useState } from 'react';
import Select from 'react-select';
import { useContractWrite } from 'wagmi';
import { useForm, Controller } from 'react-hook-form';
import { countries } from '../../helpers/constants';
import { appSettings } from '../../helpers/settings';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useApp from '../../hooks/useApp';
import useUser from '../../hooks/useUser';

// COMPONENTS
import ConnectWalletHander from '../../components/general/ConnectWalletHandler';

function SignUpForm() {
    const { account } = useWeb3();
    const { setTransactionLoading } = useApp();
    const { userContract, usersList, userContractAbi, loadUserInfo, loadUsersList } = useUser();
    const [submit, setSubmit] = useState(false);
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* --------------------------------------------- 
         USER REGISTRATION HANDLER
    --------------------------------------------- */
    const { write: web3RegisterHandler } = useContractWrite({
        address: userContract?.address,
        abi: userContractAbi,
        functionName: 'createUser',
        onMutate() {
            setTransactionLoading(true);
        },
        onSuccess() {
            setTimeout(() => {
                setTransactionLoading(false);
                loadUserInfo(userContract, account);
                loadUsersList(userContract);
                toast.success('You have been sucessfully registered');
                setSubmit(true);
                navigate('/account');
            }, 5000);
        },
        onError() {
            setSubmit(false);
            setTransactionLoading(false);
            toast.error('Something went wrong');
        },
    });

    /* --------------------------------------------- 
          HANDLE REGISTER FORM SUBMIT
    --------------------------------------------- */
    async function handleFormSubmit(data) {
        setTransactionLoading(true);

        const formData = new FormData();
        formData.append("file", data?.profile[0]);

        const resFile = await axios({
            method: "POST",
            url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
            data: formData,
            headers: {
                'pinata_api_key': `${appSettings.IPFSProjectID}`,
                'pinata_secret_api_key': `${appSettings.IPFSSecret}`,
                "Content-Type": "multipart/form-data"
            },
        });

        const ImgHash = `${resFile.data.IpfsHash}`;


        setSubmit(true);
        if (ImgHash) {
            web3RegisterHandler({
                recklesslySetUnpreparedArgs: [
                    data?.full_name,
                    data?.email,
                    data?.location?.label,
                    `https://${appSettings.IPFSGatewaySubdomain}/ipfs/${ImgHash}`,
                    data?.mobile,
                ],
            });
        }
    }

    /* --------------------------------------------- 
          VALIDATE PROFILE IMAGE FILE TYPE
    --------------------------------------------- */
    const validateImageFileType = (file) => {
        const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!validImageTypes.includes(file[0].type)) {
            return 'Image type should be .png or .jpg';
        }
    };

    /* --------------------------------------------- 
          VALIDATE UNIQUE EMAIL ADDRESS
    --------------------------------------------- */
    const validateUniqueEmail = (email) => {
        if (usersList?.map((user) => user?.email)?.includes(email)) {
            return 'This email address is already registered';
        }
    };

    return (
        <>
            {!submit && (
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className='row g-4'>
                        {/* FULL NAME */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='fullName'>
                                Full Name
                            </label>
                            <input
                                type='text'
                                className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                id='fullName'
                                placeholder='Enter your full name'
                                name='full_name'
                                {...register('full_name', {
                                    required: {
                                        value: true,
                                        message: 'Enter your full name',
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Full name must be more than 8 characters',
                                    },
                                })}
                            />
                            {errors.full_name && <span className='invalid-feedback'>{errors.full_name?.message}</span>}
                        </div>

                        {/* EMAIL ADDRESS */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='email'>
                                Email Adderss
                            </label>
                            <input
                                type='email'
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                id='email'
                                placeholder='Enter your email address'
                                name='email'
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Enter your email address',
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Enter valid email address',
                                    },
                                    validate: validateUniqueEmail,
                                })}
                            />
                            {errors.email && <span className='invalid-feedback'>{errors.email?.message}</span>}
                        </div>

                        {/* PHONE NUMBER */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='phoneNumber'>
                                Phone Number
                            </label>
                            <input
                                type='tel'
                                className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                id='phoneNumber'
                                placeholder='Enter your phone number with country code'
                                name='mobile'
                                {...register('mobile', {
                                    required: {
                                        value: false,
                                    },
                                })}
                            />
                        </div>

                        {/* LOCATION */}
                        <div className='col-lg-6'>
                            <label className='form-label' htmlFor='location'>
                                Location
                            </label>
                            <Controller
                                name='location'
                                control={control}
                                rules={{ required: 'Please select a country' }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            options={countries}
                                            id='location'
                                            className={`border-0 shadow-sm ${errors.location ? 'is-invalid' : ''}`}
                                            classNamePrefix='select'
                                            placeholder='Select'
                                            isSearchable={true}
                                            {...field}
                                        />
                                        {errors.location && (
                                            <span className='invalid-feedback'>{errors.location?.message}</span>
                                        )}
                                    </>
                                )}
                            />
                        </div>

                        <div className='col-lg-12'>
                            <label className='form-label' htmlFor='profile'>
                                Profile Picture
                            </label>
                            <input
                                type='file'
                                className={`form-control ${errors.profile ? 'is-invalid' : ''}`}
                                id='profile'
                                accept='.jpg, .png, .jpeg'
                                name='profile'
                                {...register('profile', {
                                    required: {
                                        value: true,
                                        message: 'Please upload a profile picture',
                                    },
                                    validate: validateImageFileType,
                                })}
                            />
                            {errors.profile && <span className='invalid-feedback'>{errors.profile?.message}</span>}
                        </div>

                        <div className='col-12'>
                            {account ? (
                                <button className='btn btn-primary' type='submit'>
                                    Sign up now
                                </button>
                            ) : (
                                <ConnectWalletHander />
                            )}
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}

export default SignUpForm;
